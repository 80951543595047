* {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif;
}

input, select {
  display: flex;
  font-size: 12px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid black;
  background-color: white;   
  border-color: #ccc;
  outline-color: #ccc !important; 
}

select {
  height: 32px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* MultiSelectCustom width 100% nos selecionados */
.rmsc .select-item {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: var(--rmsc-p);
  outline-offset: -1px;
  outline-color: var(--rmsc-primary);
  display: flex;
  width: 100%;
}

/* MultiSelectCustom SEM O FOCUS AZUL */
.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  border-color: #ccc !important;
  outline-color: #ccc !important;
}


/* MultiSelectCustom ITENS */
.rmsc .dropdown-heading {
  height: 30px !important;
  font-size: 12px;
}