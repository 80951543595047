.buttonOK {
    padding: 10px;
    background-color: #b72126;
    border: none;
    border-radius: 5px;
    min-width: 65px;
    outline: none;
    color: white;
    font-size: 14px;
    font-weight: 400;
}
.buttonOK:hover {
    cursor: pointer;
}

.buttonPesquisa {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    padding: 7px 15px 7px 15px; /* topo | direita | inferior | esquerda */
    background-color: rgb(220, 220, 220, 0.9);
    border: none;
    /* min-width: 50px; */
}

.buttonPesquisa:hover {
    cursor: pointer;
    background: silver
}

.buttonImprime {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 15px 5px 15px; /* topo | direita | inferior | esquerda */
    background-color: rgb(220, 220, 220, 0.9);
    border: none;
    /* min-width: 50px; */
}

.buttonImprime:hover {
    cursor: pointer;
    background: silver
}