.bloqueto-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding: 10px;
}

.bloqueto-filtros {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 10px;
    margin-top: 10px;
}

.bloqueto-filtro {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.bloqueto-filtro-titulo {
    font-size: 16px;
    font-weight: 500;
}

.bloqueto-filtro-input {
    font-size: 16px;
    font-weight: 300;
    padding: 5px;
    border-radius: 4px;
    border-width: 1px;
    border-color: silver;
    background-color: #FFF;   
    outline: none;
    user-select: none;
}

.bloqueto-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
    gap: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* .bloqueto-main::-webkit-scrollbar {
    width: 5px;
  }
  
.bloqueto-main::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
}
  
.bloqueto-main::-webkit-scrollbar-thumb {
    background-color: lightslategrey;
    outline: 1px solid lightslategrey;
    border-radius: 10px;
} */

.bloqueto-title {
    display: flex;
    font-size: 18px;
}

.bloqueto-item {
    display: flex;
    min-height: 120px;
    width: 90%;
    max-width: 600px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 3px gainsboro;
    padding: 10px;
}

.bloqueto-details {
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.bloqueto-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    color: slategray;
}

.bloqueto-detail-title {
    width: 90px;
    font-size: 14px;
    font-weight: 300;
    text-align: right;
}

.bloqueto-detail-value {
    font-size: 14px;
    font-weight: 700;
    flex-wrap: nowrap;
}

.bloqueto-detail-span {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
}

.bloqueto-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* justify-content: space-between; */
    /* padding: 10px; */
    font-size: 14px;
    font-weight: 300;
}

.bloqueto-btn-options {
    display: flex;
    text-align: center;
    /* background-color: rgba(0, 0, 255, 0.1); */
    border-radius: 5px;
    /* color: rgba(30, 144, 255, 0.75); */
    color: rgba(0, 0, 0, 0.65);
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
}
.bloqueto-btn-options:hover {
    cursor: pointer;
    /* background-color: rgba(30, 144, 255, 0.75); */
    /* color: white; */
    background-color: rgba(0, 0, 255, 0.1);
    border-radius: 50px;
}

.bloqueto-item-status-pago {
    display: flex;
    background-color: green;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-size: 12px;
}

.bloqueto-item-status-pendente {
    display: flex;
    background-color: dodgerblue;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-size: 12px;
}

.bloqueto-item-status-vencido {
    display: flex;
    background-color: orangered;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-size: 12px;
}

.bloqueto-sem-registro {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 26px;
    font-weight: 300;
}

.bloqueto-qrcode-content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 15px;
}