.menu-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #ededed;
}

.menu-main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #ededed;
}

.menu-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    gap: 15px;
    color: slategray;
    min-height: 55px;
    border-bottom: 1px solid gainsboro;
}
.menu-header-avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.menu-header-user {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
}

.menu-header-user-name {
    font-size: 14px;
    color: slategray;
    font-weight: bold;
    text-align: center;
}

.menu-header-user-password {
    font-size: 12px;
    color: slategray;
    font-weight: 500;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
}

.menu-header-options {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.menu-bottom {
    display: flex;
    margin-top: 70px;
    width: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.menu-header-user-options {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-end;
    gap: 5px;
    width: 100%;
}

.menu-password-content {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    gap: 15px;
    width: 99%;
}

.menu-password-alert {
    color: #b72126;
    font-size: 14px;
    font-weight: 800;
}

.menu-password-container-input {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px;
    background-color: white;
    border-radius: 6px;
    border: 1.2px solid silver;
    flex: 1;
}

.menu-password-input {
    display: flex;
    flex: 1;
    padding: 3px;
    border: none;
    background: white;
    outline: none;
    font-size: 14px;
}

.menu-password-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
}