.page-404-container {
    display: flex;
    background-color: #ededed;
    flex-direction: column;    
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    color: black;
    gap: 15px;
}

.page-404-titulo {
    font-size: 24px;
    font-weight: 300;
}

.page-404-home {
    outline: none;
    margin-top: 45px;
    border: none;
    color: navy;
    background-color: transparent;
    padding: 15px;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
}
