.header-container {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: white;
    padding: 10px;
}

.header-title {
    flex: 1;
    /* text-align: right; */
    font-size: 20px;
    color: gray;
    font-weight: 300;
}

.header-logo {
    height: 50px;
    object-fit: cover;
}

.header-user {
    flex: 1;
    text-align: right;
    font-size: 1.1rem;
    font-weight: 700;
}