.container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    background-color: rgb(192, 192, 192, 0.35);
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 30px;
    width: 100vw;
    max-width: 280px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 1px silver;
}

.logo {
    height: 110px;
    object-fit: cover;
    margin-bottom: 10px;
}

.span-alert {
    color: #b72126;
    font-size: 14px;
    font-weight: 800;
}

.container-input {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px;
    background-color: white;
    border-radius: 6px;
    border: 1.2px solid silver;
    flex: 1;
}

.input {
    display: flex;
    flex: 1;
    padding: 3px;
    border: none;
    background: white;
    outline: none;
    font-size: 14px;
}

.version {
    font-size: 12px;
    font-weight: bold;
    color: gray;
}

.lost-password {
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 15px;
}

.forgot-password-content {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    gap: 15px;
    width: 99%;
}

.forgot-password-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
}