.os-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding: 10px;
}

.os-filtros {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 20px;
    margin-top: 10px;
}

.os-filtro {
    display: flex;
    flex-direction: column;
    gap: 5px;    
}

.os-filtro-titulo {
    font-size: 16px;
    font-weight: 500;
}

.os-filtro-input {
    display: flex;
    font-size: 16px;
    font-weight: 300;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid silver;
    background-color: white;   
    /* outline: none; */
    outline-color: gray;
}

.os-filtro-data {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
}

.os-marca-todos {
    display: flex;
    gap: 10px;
    padding: 3px 10px 3px 10px; /* topo | direita | inferior | esquerda */
}

.os-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
    gap: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* .os-main::-webkit-scrollbar {
    width: 5px;
  }
  
.os-main::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
}
  
.os-main::-webkit-scrollbar-thumb {
    background-color: lightslategrey;
    outline: 1px solid lightslategrey;
    border-radius: 10px;
} */

.os-item {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 600px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 3px gainsboro;
    padding: 10px;
    gap: 10px;
}

.os-item-title {
    padding: 10px;
    max-width: 600px;
    font-size: 14px;
    font-weight: 700;
    color: slategray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
}

.os-item-print {
    display: flex;
    color: slategray;
    gap: 10px;
}

.os-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    color: slategray;
}

.os-detail-titulo {
    width: 110px;
    font-size: 14px;
    font-weight: 300;
    text-align: right;
}

.os-detail-valor {
    flex: 1;
    font-size: 14px;
    font-weight: 700;
    flex-wrap: nowrap;
}

.os-options {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
}

.os-options-button {
    display: flex;
    text-align: center;
    background-color: rgba(0, 0, 255, 0.1);
    border-radius: 5px;
    color: dodgerblue;
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
}
.os-options-button:hover {
    cursor: pointer;
    background-color: dodgerblue;
    color: white;
}

.os-sem-registro {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 26px;
    font-weight: 300;
}

.os-modal-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
    padding: 5px;
    gap: 10px;
}
/* .os-modal-content::-webkit-scrollbar {
    width: 3px;
  }
  
.os-modal-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
}
  
.os-modal-content::-webkit-scrollbar-thumb {
    background-color: lightslategrey;
    outline: 1px solid lightslategrey;
    border-radius: 10px;
} */

.os-modal-details {
    display: flex;
    font-size: 14px;
    flex: 1;
    flex-direction: row;
    color: slategray;
    gap: 10px;
}

.os-modal-detail {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 3px;
}

.os-modal-detail-titulo {
    font-size: 12px;
    flex: 1;
    font-weight: 500;
}

.os-modal-detail-valor {
    font-size: 13px;
    font-weight: 700;
}